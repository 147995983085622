const pricingData = [
    {
        name: 'Starter',
        text: 'Neque quis est',
        icon: 'bx-walk',
        price: 19
    },
    {
        name: 'Professional',
        text: 'Quis autem iure',
        icon: 'bx-run',
        price: 29
    },
    {
        name: 'Enterprise',
        text: 'Sed ut neque unde',
        icon: 'bx-cycling',
        price: 39
    },
    {
        name: 'Unlimited',
        text: 'Itaque earum hic',
        icon: 'bx-car',
        price: 49
    },
];

export { pricingData }; 
